import {useNavigate, useParams, Link  } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import AuthService from "../services/AuthService";
import UserService from "../services/UserService";
import AssistantService from "../services/AssistantService";
import PIMServices from "../services/PIMServices";
import PromptService from "../services/PromptService";
import ImproveJobQuestion from "./ImproveJobQuestion";
import "../styles/bootstrap.min.css";
import 'semantic-ui-css/components/icon.min.css';
import TopBar from "./Topbar";
import SideBar from "./Sidebar";
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'semantic-ui-react'
function PIMConfig() {
  document.body.style.backgroundColor = '#FFFFFF';
  const { t } = useTranslation();
  const [organizationID, setOrganizationID] = useState(parseInt(window.sessionStorage.getItem('organization_id'),10));
  const [PIMSaveMsg, setPIMSaveMsg] = useState();
  const [dailyWeeklyMonthly, setDailyWeeklyMonthly] = useState();
  const [monthDay, setMonthDay] = useState();
  const [showPIMIntervals, setShowPIMIntervals] = useState(false);
  const [selectedTime, setSelectedTime] = useState();
  const [assistantID, setAssistantID] = useState("");
  const [saveFrequencyMsg, setSaveFrequencyMsg] = useState();
  const [showPIMImportAttr, setShowPIMImportAttr] = useState(false);
  const [attributesList, setAttributesList] = useState();
  const [selectedAttribute, setSelectedAttribute] = useState([]);
  const [selectedAttributeIDs, setSelectedAttributeIDs] = useState([]);
  const [showPIMAttributeSchedule, setShowPIMAttributeSchedule] = useState(false);
  const [showFirstImportButton, setShowFirstImportButton] = useState(false);
  const [firstImportEnable, setFirstImportEnable] = useState("disabled");
  const [productCount, setProductCount] = useState(0);
  const [totalProducts, setTotalProducts] = useState("");
   //const differenceInHours = useState("");
   //console.log("selectedAttributeIDs", selectedAttributeIDs);
  const [selectedDays, setSelectedDays] = useState({
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
  });
  const totalPrcent = Math.round(productCount*100/totalProducts);
   const selectWeeklyDays = (day) => {
    setSelectedDays((prevSelectedDays) => ({
      ...prevSelectedDays,
      ...day,
    }));
  };
  //console.log("selectedTime", selectedTime.format('hh:mm:ss'));
  const days = Array.from({ length: 31 }, (_, index) => index + 1);
  const handleMonthDayChange = (event) => {
    setMonthDay(event.target.value);
  }
  const ResumeStopFirstImport = (resumestop) => {
    if(resumestop=="stopped") {
      var confirmed = window.confirm("Are you sure you want to stop the product import?");
    } else {
      var confirmed = true;
    }

    if (confirmed) {
      PIMServices.resumeStopImport(resumestop, organizationID).then(
        (response) => {
          console.log(response)
          setShowFirstImportButton(!showFirstImportButton);
          setFirstImportEnable(resumestop)
        }
      ).catch(
        (error) => {
          console.log("error in stop/resume first import", error)
        }
      )
    }
  }
  const handleSelectChange = (event) => {
    setSelectedDays({
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false,
    })
    setDailyWeeklyMonthly(event.target.value);
  };
  const times = Array.from({ length: 24 }, (_, i) => {
    const hour = i.toString().padStart(2, '0'); // Pad single digits with a leading zero
    return `${hour}:00`;
  });
  const handleTimeChange = (event) => {
    /*if (time) {
        // Set the minutes to 00
        const adjustedTime = time ? time.minutes(0) : null;*/
        setSelectedTime(event.target.value+':00');
    //}
    ///setSelectedTime(value);
  };
 
  const [PIMFields, setPIMFields] = useState({
    pim_url:"",
    pim_username: "",
    pim_password: "",
    pim_structure: "",
    last_cron_date: ""
  });
  

  useEffect(() => {
    PIMServices.PIMAttributes(organizationID).then(
      (response) => {
        //console.log(response.data);
        //setAttributesList(response.data);
        setAttributesList(prevList => {
          // Check if response.data exists and is an array before mapping
          if (Array.isArray(response.data)) {
            const newItems = response.data.map((values) => ({
              value: values.id,
              text: values.name
            }));

            // Merge the previous list with the new items
            return [...(prevList || []), ...newItems]; // Ensure prevList is an array, even if initially undefined
          }
          return prevList || []; // Return the previous list or an empty array if data is invalid
        });
      }
    ).catch(
      (error) => {
        console.log("error fetching attributes", error);
      }
    )

    PIMServices.getPIMConfig(organizationID).then(
      (response) => {
        let differenceInHours = "0000";
        let differenceInMinutes = "";
        let formattedDifference = "0000";
        
        //console.log("response.config.last_cron_date", response.config.last_cron_date);
        if(response.config.last_cron_date==null || response.config.last_cron_date=="0000-00-00 00:00:00"){
          //differenceInHours = 0;
          
        } else {
          const parsedLastCronDate = new Date(response.config.last_cron_date);
          const currentDate = new Date();
          const differenceInMillis = currentDate - parsedLastCronDate;
          const differenceInHours = Math.floor(differenceInMillis / (1000 * 60 * 60));
          const differenceInMinutes = Math.floor((differenceInMillis % (1000 * 60 * 60)) / (1000 * 60));

          if (differenceInHours > 0) {
            formattedDifference = `${differenceInHours} hours and ${differenceInMinutes} minutes`;
          } else {
            formattedDifference = `${differenceInMinutes} minutes`;
          }
          //console.log("founddddddd");
          //console.log("response.config.last_cron_date", response.config.last_cron_date);
        }
        console.log("differenceInHours", differenceInHours)
        setPIMFields({
          pim_url: response.config.pim_url,
          pim_username: response.config.pim_username,
          pim_password: response.config.pim_password,
          pim_structure: response.config.pim_structure,
          last_cron_date: formattedDifference
        })
        setTotalProducts(response.config.total_products)
        setShowPIMAttributeSchedule(true)
      }
    ).catch(
      (error) => {
        console.log("Error fetching PIM config", error)
      }
    )
    PIMServices.GetPIMFrequency(organizationID).then(
      (response) => {
        setShowFirstImportButton(true)
        setShowPIMIntervals(true)
        setDailyWeeklyMonthly(response.pimfrequency.frequency)
        setSelectedTime(response.pimfrequency.time)
        setMonthDay(response.pimfrequency.days)
        setFirstImportEnable(response.pimfrequency.first_import)
        setSelectedDays(JSON.parse(response.pimfrequency.days))
        setProductCount(response.product_count)
        //console.log("aaaaaaaa",response.pimfrequency.attribute_ids.split(',').map(Number));
        setSelectedAttributeIDs(response.pimfrequency.attribute_ids.split(',').map(Number));
        //setSelectedAttribute(response.attributes)
        setShowPIMImportAttr(true);
        
      }
    ).catch(
      (error) => {
        console.log("Error fetching PIM Frequency", error)
      }
    )
  },[PIMSaveMsg])

  const setFirstImportReady = (organizationID) => {
    var confirmed = window.confirm("Are you sure you want to start product import?");
    if(confirmed){
      setFirstImportEnable("started")
      //console.log("setFirstImportReady", organizationID);
      PIMServices.setPIMFirstImportStart(organizationID).then(
        (response) => {
         setShowFirstImportButton(false)
        }
      ).catch(
        (error) => {
          console.log("Error saving PIM config", error.response.data.error)
        }
      )
    }
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    PIMServices.savePIMConfig(organizationID, PIMFields).then(
      (response) => {
        if(response.accessToken.error=="invalid_client"){
          setPIMSaveMsg(t('pimauthfailedmessage'))
        } else {
          setPIMSaveMsg(response.message)
          setShowPIMAttributeSchedule(true)
        }
      }
    ).catch(
      (error) => {
        setPIMSaveMsg(t('pimauthfailedmessage'))
        console.log("Error saving PIM config", error.response.data.error)
      }
    )
  }
  const handleChange = (event) => {
    if(event.keyCode=='13'){
      handleSubmit(event);
    }
    setPIMFields({
        ...PIMFields,
        [event.target.name]: event.target.value
    });
  };

  const savePIMFrequency = () => {
    var confirmed = window.confirm("Are you sure you want to save the attributes?");
    if(confirmed){
      const params = {
        frequency : dailyWeeklyMonthly,
        days : dailyWeeklyMonthly=='weekly' ? JSON.stringify(selectedDays) : monthDay,
        time : selectedTime,
      }
      console.log("selectedDays", selectedDays, dailyWeeklyMonthly, monthDay)
      if(selectedAttributeIDs.length === 0){
        setSaveFrequencyMsg(t('attributesseelctionmandatory'));
        return
      } 
      if(!dailyWeeklyMonthly){
        setSaveFrequencyMsg(t('frequencyselectionmandatory'));
        return
      } 
      if(dailyWeeklyMonthly=='weekly' && !Object.values(selectedDays).some(value => value)){
        setSaveFrequencyMsg(t('frequencydaysselectionmandatory'));
        return
      }
      if(dailyWeeklyMonthly=='monthly' && !monthDay){
        setSaveFrequencyMsg(t('frequencydaysselectionmandatory'));
        return
      } 

      PIMServices.SetPIMFrequency(organizationID, dailyWeeklyMonthly, params.days, params.time, selectedAttributeIDs.join(',')).then(
        (response) => {
          console.log(response);
          setSaveFrequencyMsg(response.message);
          setShowFirstImportButton(true);
        }
      ).catch(
        (error) => {
          console.log("Error setting frequency", error);
        }
      )
    }
    //console.log(dailyWeeklyMonthly, selectedDays, monthDay, selectedTime.format('hh:mm:ss'));
  }

  const handleAttributeSelect = (event, data) => {
    setSelectedAttributeIDs(data.value)
      
  };
	return (
        <div  >
            <div className="container-fluid">
              <div className="row">
                

                <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                  <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center d-none d-block pt-3 pb-2 mb-3">
                    <button className="navbar-toggler bg-light position-absolute d-md-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
                      <i className="bi bi-list"></i>
                    </button>
                  </div>
                  <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3  mb-1">
                    <div className="col-md-3 col-lg-7 px-4 ">
                      <h1 className="h1">{t('pimconfig')}</h1>
                      <div className="breadcrumb">
                        <Link  to={"/tools"}>{t('tools')}</Link>&nbsp;&raquo;&nbsp;{t('pimconfig')}
                      </div>
                    </div>
                    <TopBar />
                  </div>
                  <div className="container-fluid">
                  <hr />
                  </div> 
                  <div className="container-fluid mt-4">
                    <form action="post"  onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-12">
                        <h6>{t('pimurl')}</h6>
                      </div>
                      <div className="col-8">
                        
                        <input
                          type="text"
                          name="pim_url"
                          className="form-control"
                          placeholder=""
                          aria-label=""
                          aria-describedby=""
                          value={PIMFields.pim_url}
                          onChange={handleChange} 
                        />
                      </div>
                      <div className="col-12  pt-4">
                        <h6>{t('pimusername')}</h6>
                      </div>
                      <div className="col-8">
                        
                        <input
                         type="text"
                          name="pim_username"
                          className="form-control"
                          placeholder=""
                          aria-label=""
                          aria-describedby=""
                          value={PIMFields.pim_username}
                          onChange={handleChange} 
                        />
                      </div>
                      <div className="col-12  pt-4">
                        <h6>{t('pimpassword')}</h6>
                      </div>
                      <div className="col-8">
                        
                        <input
                          type="password"
                          name="pim_password"
                          className="form-control"
                          placeholder=""
                          aria-label=""
                          aria-describedby=""
                          value={PIMFields.pim_password}
                          onChange={handleChange} 
                        />
                      </div>
                      <div className="col-12  pt-4">
                        <h6>{t('pimstructureid')}</h6>
                      </div>
                      <div className="col-8">
                        
                        <input
                          type="text"
                          name="pim_structure"
                          className="form-control"
                          placeholder=""
                          aria-label=""
                          aria-describedby=""
                          value={PIMFields.pim_structure}
                          onChange={handleChange} 
                        />
                      </div>
                      <div className="col-12  pt-4">
                        <button className="btn btn-md fm-btn me-md-2" type="submit" >{t('saveandauthenticate')}</button>
                        {PIMSaveMsg ? PIMSaveMsg : ''}
                      </div>
                    </div>
                    </form>
                  </div>
                  {showPIMAttributeSchedule ? 
                    (
                    <>
                  <div className="container-fluid mt-4">
                  <hr />
                  </div>
                  <div className="container-fluid mt-2">
                    <div className="row">
                      <div className="col-8">
                        <h4 className="h4">{t('automaticpimimport')}</h4>
                        <p>{t('automaticpimimportinstruction')}</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-2">
                        
                      </div>
                      
                    </div>
                    <div className="row">

                      <div className="col-4">
                        <div className="pt-3">
                          
                           Last PIM import: {PIMFields.last_cron_date=="0000" ? "Never" : PIMFields.last_cron_date }
                          
                        </div>
                      </div>
                      <div className="col-1">
                        {firstImportEnable =="started" ? 
                        (
                          <>
                            <button className="btn btn-md fm-btn me-md-2" onClick={() => ResumeStopFirstImport('stopped') } type="submit" >
                            {t('stop')}
                            </button>
                          </>
                        ) : 
                          firstImportEnable =="stopped" ? 
                          (
                          <>
                            <button className="btn btn-md fm-btn me-md-2" onClick={() => ResumeStopFirstImport('started') } type="submit" >
                            {t('resume')}
                            </button>
                          </>
                          ) : " "
                        }
                      </div>
                      <div className="col-3">
                        <div className="pt-3">
                          <div className="creditProgressBarContainer">
                            <div className="creditProgressBarGreen" style={{width: totalPrcent+"%" }}>&nbsp;</div>
                          </div>
                          <div className="creditProgressText"><b>{t('imported')}:</b> {productCount} {t('outof')} {totalProducts}. <b>{t('remaining')}:</b> {totalProducts-productCount}</div> 
                           
                        </div>
                          
                      </div>
                    </div>
                    <div className="row">
                      <div className="mt-4">
                      <h6 className="h5">{t('selectattributesforimport')}</h6>
                        {/*}<button className="btn btn-lg fm-btn me-md-2" type="button" onClick={() =>  setShowPIMImportAttr(!showPIMImportAttr) } >{t('selectattributesforimport')}</button> {*/}
                      </div>
                    </div>
                    
                    <div className="row pt-4">
                      <div className="col-8">
                          <Dropdown value={selectedAttributeIDs}  fluid search multiple clearable selection   onChange={(event, data) => handleAttributeSelect(event, data)}  options={attributesList} />
                           
                      </div>
                    </div>
                    <div className="row pt-2">
                      <div className="col-12">
                        <div className="row">

                          {selectedAttribute.map((value, index) => (
                              <div className="attrib">
                              {value.name}
                              </div>
                          ))}
                         
                        </div>
                      </div>
                    </div>
                    
                    <div className="row">
                      <div className=" col-8 mt-4">
                        <h6 className="h5">{t('selectintervalforimimport')}</h6>
                        <p>{t('selectintervalforimimportsubline')}</p>
                        {/*}<button className="btn btn-lg fm-btn me-md-2" type="button" onClick={() => setShowPIMIntervals(!showPIMIntervals)} >{t('selectintervalforimimport')}</button>{*/}
                      </div>
                    </div>
                    
                    <div className="row pt-4">
                      <div className="col-2">

                        <select className="form-control" onChange={handleSelectChange}>
                          <option value="">Select</option>
                          <option value="hourly" selected={dailyWeeklyMonthly=="hourly" ? "selected" : ""}>Hourly</option>
                          <option value="daily" selected={dailyWeeklyMonthly=="daily" ? "selected" : ""}>Daily</option>
                          <option value="weekly" selected={dailyWeeklyMonthly=="weekly" ? "selected" : ""}>Weekly</option>
                          <option value="monthly" selected={dailyWeeklyMonthly=="monthly" ? "selected" : ""}>Monthly</option>
                        </select>
                      </div>
                      <div className="col-5">
                        {dailyWeeklyMonthly=="daily" ?
                        (
                        <div className="row">
                          <div className="col-4">
                          <select className="form-control" onChange={handleTimeChange} >
                            {times.map((time, index) => (
                              <option selected={selectedTime==time+':00' ? "selected" : ""} key={index} value={time}>
                                {time}
                              </option>
                            ))}
                          </select>
                          </div>
                         </div>)
                        : dailyWeeklyMonthly=="weekly" ?
                          (
                          <div className="row">
                            <div  className={`col-1 p-0 weeklydays ml-1 ${selectedDays.monday ? 'selectedDay' : ''}`} onClick={() => selectWeeklyDays({monday: !selectedDays.monday})}>M</div>
                            <div  className={`col-1 p-0 weeklydays ml-1 ${selectedDays.tuesday ? 'selectedDay' : ''}`} onClick={() => selectWeeklyDays({tuesday: !selectedDays.tuesday})}>T</div>
                            <div  className={`col-1 p-0 weeklydays ml-1 ${selectedDays.wednesday ? 'selectedDay' : ''}`} onClick={() => selectWeeklyDays({wednesday: !selectedDays.wednesday})}>W</div>
                            <div  className={`col-1 p-0 weeklydays ml-1 ${selectedDays.thursday ? 'selectedDay' : ''}`} onClick={() => selectWeeklyDays({thursday: !selectedDays.thursday})}>T</div>
                            <div  className={`col-1 p-0 weeklydays ml-1 ${selectedDays.friday ? 'selectedDay' : ''}`} onClick={() => selectWeeklyDays({friday: !selectedDays.friday})}>F</div>
                            <div  className={`col-1 p-0 weeklydays ml-1 ${selectedDays.saturday ? 'selectedDay' : ''}`} onClick={() => selectWeeklyDays({saturday: !selectedDays.saturday})}>S</div>
                            <div  className={`col-1 p-0 weeklydays ml-1 ${selectedDays.sunday ? 'selectedDay' : ''}`} onClick={() => selectWeeklyDays({sunday: !selectedDays.sunday})}>S</div>
                            <div className="col-3">
                              <select className="form-control" onChange={handleTimeChange} >
                                {times.map((time, index) => (
                                  <option selected={selectedTime==time+':00' ? "selected" : ""} key={index} value={time}>
                                    {time}
                                  </option>
                                ))}
                              </select>
                            </div>
                            </div>)
                        : dailyWeeklyMonthly=="monthly" ?
                          (<div className="row">
                            <div  className="col-3">
                            
                            <select className="form-control" onChange={handleMonthDayChange}>
                              {days.map((day) => (
                                <option key={day} value={day} selected={monthDay==day ? "selected" : ""}>
                                  {day}
                                </option>
                              ))}
                            </select>
                            </div>
                            <div className="col-3">
                              <select className="form-control" onChange={handleTimeChange} >
                                {times.map((time, index) => (
                                  <option selected={selectedTime==time+':00' ? "selected" : ""} key={index} value={time}>
                                    {time}
                                  </option>
                                ))}
                              </select>
                              
                            </div>
                          </div>)
                        : ''
                        }
                      </div>
                      
                    </div>
                    <div className="row pt-4">
                      <div className="col-12">
                        <button disabled={firstImportEnable == "started" ? "disabled" : ""} className="btn btn-md fm-btn me-md-2" type="button" onClick={() => savePIMFrequency()} >{t('save')}</button>
                        {saveFrequencyMsg ? saveFrequencyMsg : ''}
                      </div>
                    </div>
                    <div className="row pt-4">
                      <div className="col-12">
                        {showFirstImportButton && firstImportEnable =="disabled"? 
                          (
                            <>
                        <button className="btn btn-md fm-btn me-md-2" type="button" onClick={() => setFirstImportReady(organizationID) } >{t('letsstartimporttingproducts')}</button>
                        </>
                        ) : ""}
                      </div>
                    </div>
                    <div className="row pt-4">
                      <div className="col-12">
                        <br />
                      </div>
                    </div>
                  </div>
                  </>
                    )
                    : ''}  
                  
                </main>
              </div>
            </div>
        	
        </div>
        )
}
export default PIMConfig;