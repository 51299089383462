import {useNavigate, useParams, Link  } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import AuthService from "../services/AuthService";
import UserService from "../services/UserService";
import AssistantService from "../services/AssistantService";
import PromptService from "../services/PromptService";
import ImproveJobQuestion from "./ImproveJobQuestion";
import ImproveJobOneQuestion from "./ImproveJobOneQuestion";
import "../styles/bootstrap.min.css";
import TopBar from "./Topbar";
import SideBar from "./Sidebar";
import UserAvatar  from "../images/user-avatar.png";
import OurValues  from "../images/our-values.png";
import ToneOfVoice  from "../images/tone-voice.png";
import Language  from "../images/language.png";
import JobResult  from "../images/job-result.png";
import Feedback  from "../images/feedback.png";
import Notification  from "../images/notifications_none.png";
import Theme  from "../images/moon-solid.png";
import CloseIcon  from "../images/close.png";

import Information  from "../images/info_outline.png";
import { useTranslation } from 'react-i18next';
import CloseCircle  from "../images/cross-circle.png";

function ImproveJob() {
  const { appslug } = useParams();
  document.body.style.backgroundColor = '#FFFFFF';
  const { t } = useTranslation();
  const [assistantID, setAssistantID] = useState(false);
  const [userID, setUserID] = useState(parseInt(window.sessionStorage.getItem('user_id'),10));
  const [organizationID, setOrganizationID] = useState(parseInt(window.sessionStorage.getItem('organization_id'),10));
  const [showSaveMsgButton, setShowSaveMsgButton] = useState(false);
  const [outputSavedMsg, setOutputSavedMsg] = useState(null);
  const [savePromptMsg, setSavePromptMsg] = useState("");
  const [executeMessage, setExecuteMessage] = useState(null);
  const [output, setOutput] = useState(null);
  const [promptMessage, setPromptMessage] = useState(null);
  const [prompt, setPrompt] = useState(null);
  const [isbn, setISBN] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [userPromptMessage, setUserPromptMessage] = useState("");
  const [systemdefinedString, setSystemdefinedString] = useState("");
  const [productDetails, setProductDetails] = useState("");

   const SystemPrompt = ({ text }) => (
    <div dangerouslySetInnerHTML={{ __html: text.replace(/\n/g, '<br />') }} />
  );
  const handleChange = (event) => {
    setISBN(event.target.value);
  };
  const addQ1Row = () => {

  const newQ1Row = { id: q1Rows.length + 1, heading: '', description: '' };
    setQ1Rows([...q1Rows, newQ1Row]);
  };

  const removeQ1Row = (id) => {
    const newQ1Rows = q1Rows.filter(row => row.id !== id).map((row, index) => ({
      ...row,
      id: index + 1
    }));
    setQ1Rows(newQ1Rows);
  };
    const [q1Question, setQ1Question] = useState();
    const [q1Help, setQ1Help] = useState();

  const [q1Rows, setQ1Rows] = useState([
    { id: 1, heading: '', description: '' }
  ]);
  //console.log("q1Rows", q1Rows);
  useEffect(() => {
    AssistantService.getAssistantIDBySlug(appslug).then(
      (assistresponse) => {
        setAssistantID(assistresponse.assistantID);
        /*PromptService.getPromptByType(assistresponse.assistantID, "questionone").then(
        (response) => { 

          const promptData = JSON.parse(response.prompt.prompt);
          setQ1Question(promptData[0].question);
          setQ1Help(promptData[0].help);
          const questionData = promptData[0].data;
          setQ1Rows( questionData)
        },
        (error) => {
        console.log(error);
        });*/
        PromptService.getPromptByType(assistresponse.assistantID, "questionone").then(
        (response) => {
          if (response && response.prompt && response.prompt.prompt) {
            const promptData = JSON.parse(response.prompt.prompt);
            const updatedTables = promptData.map((item, index) => {
              let data = [];
              if (Array.isArray(item.data)) {
               data = item.data.map((value, i) => ({
                  id: `${i}`, // Unique id for each row
                  heading: value.heading, // Unique id for each row
                  description: value.description, // Unique id for each row
                }));

              } else {
                console.error(`Invalid format in item.a for item with id ${item.q}:`, item.a);
              }
              return {
                id: item.question, // Assuming 'q' field as id for the table
                help: item.help,
                data: data
              };
            });
            setOneTables(updatedTables);
          }
        },
        (error) => {
          console.log(error);
        });
        PromptService.getPromptByType(assistresponse.assistantID, "userdefined").then(
        (response) => {
          if (response && response.prompt && response.prompt.prompt) {
            const promptData = JSON.parse(response.prompt.prompt);
            const updatedTables = promptData.map((item, index) => {
              let rows = [];
              if (Array.isArray(item.a)) {
               rows = item.a.map((value, i) => ({
                  id: `${i}`, // Unique id for each row
                  description: value
                }));

              } else {
                console.error(`Invalid format in item.a for item with id ${item.q}:`, item.a);
              }
              return {
                id: item.q, // Assuming 'q' field as id for the table
                help: item.help,
                rows: rows
              };
            });
            setTables(updatedTables);
          }
        },
        (error) => {
          console.log(error);
        });
      }
    )
  },[appslug])

  const [tables, setTables] = useState([
    { id: 0, rows: [{ id: 1, description: ''}] } 
       // Add more tables as needed
  ]);
  const [oneTables, setOneTables] = useState([
    { id: 0, data: [{ id: 1, heading: '', description: ''}] } 
       // Add more tables as needed
  ]);
  //console.log("oneTables", oneTables)
  // Function to add a new row to a specific table
  const addRow = (tableId) => {
    const newTables = tables.map(table => {
      if (table.id === tableId) {
        const newRow = { id: table.rows.length + 1, description: '', quantity: '' };
        return { ...table, rows: [...table.rows, newRow] };
      }
      return table;
    });
    setTables(newTables);
  };
  const addOneRow = (tableId) => {
    console.log("tableId", tableId)
    const newTables = oneTables.map(table => {
      if (table.id === tableId) {
        //alert(tableId);
        const newRow = { id: table.data.length + 1, heading: '', description: '' };
        return { ...table, data: [...table.data, newRow] };
      }
      return table;
    });
    console.log("newTables", newTables);
    setOneTables(newTables);
  };

  // Function to remove a row from a specific table
  const removeRow = (tableId, rowId) => {
    const newTables = tables.map(table => {
      if (table.id === tableId) {
        const newRows = table.rows.filter(row => row.id !== rowId);
        return { ...table, rows: newRows };
      }
      return table;
    });
    setTables(newTables);
  };

// Function to remove a row from a specific table
  const removeOneRow = (tableId, rowId) => {
    const newTables = oneTables.map(table => {
      if (table.id === tableId) {
        const newRows = table.data.filter(row => row.id !== rowId);
        return { ...table, data: newRows };
      }
      return table;
    });
    setOneTables(newTables);
  };

  // Function to handle input change in a row of a specific table
  const handleInputChange = (tableId, rowId, field, value) => {
    const newTables = tables.map(table => {
      if (table.id === tableId) {
        const newRows = table.rows.map(row =>
          row.id === rowId ? { ...row, [field]: value } : row
        );
        return { ...table, rows: newRows };
      }
      return table;
    });
    setTables(newTables);
  };

  // Function to handle input change in a row of a specific table
  const handleOneInputChange = (tableId, rowId, field, value) => {

    const newTables = oneTables.map(table => {
      if (table.id === tableId) {

        const newRows = table.data.map(row =>

          row.id === rowId ? { ...row, [field]: value } : row
        );
        return { ...table, data: newRows };
      }
      return table;
    });
     console.log("newTables", newTables)
    setOneTables(newTables);
  };

  const saveOutputHistory = () => {
    //console.log(executeMessage);
    const data = {
      "isbn": isbn,
      "output": output,
      "assistant_id": assistantID,
      "prompt": prompt
    }
    UserService.saveOutputHistory(data).then(
    (response) => {
      setOutputSavedMsg(response.message);
      console.log(response);
    },
    (error) => {
      console.log(error);
    });
  }
  const saveJobDescription = () => {
    //console.log(tables);
    const newTableRows = tables.map(row => {
      //return {q:row.id, a:row.a.map(item => item.description)}
      return {q:row.id, a:row.rows.map(item => item.description)}
    });

    const newOneTableRows = oneTables.map(row => {
      return {
        question: row.id,
        data: row.data.map(item => ({
          heading: item.heading,
          description: item.description,
          id: item.id
        }))
      };
    });

   
    PromptService.savePrompt(assistantID, "userdefined", newTableRows).then(
    (response) => {
      setSavePromptMsg(response.message);
      console.log(response);
    },
    (error) => {
      console.log(error);
    });
    
    PromptService.savePrompt(assistantID, "questionone", newOneTableRows).then(
    (response) => {
      setSavePromptMsg(response.message);
      //console.log(response);
    },
    (error) => {
      console.log(error);
    });

  }
  

  const openTestPopUp = () => {

    const newTableRows = tables.map(row => {
      //return {q:row.id, a:row.a.map(item => item.description)}
      const aIndex = JSON.stringify(row.rows);
      return {q:row.id, a:row.rows.map(item => item.description)}
    });
    const newOneTableRows = oneTables.map(row => {
      return {
        question: row.id,
        data: row.data.map(item => ({
          heading: item.heading,
          description: item.description,
          id: item.id
        }))
      };
    });

    console.log(newTableRows);
    PromptService.saveTempPrompt(assistantID, "userdefined", newTableRows).then(
    (response) => {
      //setSavePromptMsg(response.message);
      console.log(response);
    },
    (error) => {
      console.log(error);
    });

   

    PromptService.saveTempPrompt(assistantID, "questionone", newOneTableRows).then(
    (response) => {
      //setSavePromptMsg(response.message);
      //console.log(response);
    },
    (error) => {
      console.log(error);
    });


    handleOpenModal();
  }
  const handleClose = () => {
    setShowModal(false);
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };


  const handleSubmit = (event) => {
    event.preventDefault();
    setShowSaveMsgButton(false);
    setExecuteMessage(t('executioninprogress')+'...');
    setPromptMessage("");
    setUserPromptMessage("");
    UserService.execute(organizationID, userID, assistantID, isbn).then(
      (response) => {
        //console.log(response);
        const dataa = JSON.parse(response.data.response);
        console.log(dataa.choices[0].message.content);
        setExecuteMessage(nl2br(dataa.choices[0].message.content));
        setPromptMessage(response.data.questiononeString+response.data.userdefinedString);
        setUserPromptMessage(response.data.userPrompt);
        setProductDetails(response.data.productDetails);
        setSystemdefinedString(response.data.combinedFinalString);
        setPrompt(response.data.systemPrompt);
        setShowSaveMsgButton(true);
        setOutput(dataa.choices[0].message.content);
      }
    ).catch(
      (error) => {
        //setExecuteMessage(error);
        console.log("error", error);
      }
    );
    setShowModal(false);
        
  }

  const nl2br = (text) => {
    return text.split('\n').map((str, index) => (
      <>
        {str}
        <br />
      </>
    ));
  };

	return (
        <div  >
            <div className="container-fluid">
              <div className="row">
                

                <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                  <div className="d-flex justify-content-between flex-wrap flex-md-nowrap d-none d-block align-items-center pt-3 pb-2 mb-3">
                      <button className="navbar-toggler bg-light position-absolute d-md-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
                            <i className="bi bi-list"></i>
                          </button>
                    </div>
                  <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3  mb-1">
                    <div className="col-md-3 col-lg-7 px-4 ">
                      <h1 className="h1">{t('improvementofjobdescription')}</h1>
                      <div className="breadcrumb">
                        <Link to={"/"+appslug+"/job-description"} >{t('jobdescriptionforseoassistant')}</Link>&nbsp;&raquo; {t('improvementofjobdescription')}
                      </div>
                    </div>
                    <TopBar />
                  </div>
                  <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-0 pb-0 ">
                    <div className="col-md-3 col-lg-8 px-4 ">
                    <p>{t('improvementofjobdescriptionpagesubline')}</p>
                    </div>
                  </div>
                  
                  <div className="container-fluid ">
                    <div className="row">
                      
                          {oneTables.map(onetable => (
                            <div className="col-6 p-4">
                              <div className="col-12 job_desc_question_label">
                              <table className="table table-hover align-middle">
                              <tbody>
                                <tr className="tr-header">
                                <td>{onetable.id}  <img title={onetable.help} className="close-btn" src={Information} /></td>
                                <td align="right">{}<button className="btn fm-btn" onClick={() => addOneRow(onetable.id)} removeOneRow={(rowId) => removeOneRow(onetable.id, rowId)} type="button">{t('add')}</button></td>
                                </tr>
                               </tbody>
                              </table> 
                             
                              </div>
                              <div className="col-12">
                                <ImproveJobOneQuestion key={onetable.id}  tableId={onetable.id} rows={onetable.data} addRow={() => addOneRow(onetable.id)} removeOneRow={(rowId) => removeOneRow(onetable.id, rowId)} handleOneInputChange={(rowId, field, value) => handleOneInputChange(onetable.id, rowId, field, value)} />
                              </div>
                            </div>
                            
                          ))}
                          {tables.map(table => (
                            <div className="col-6 p-4">
                              <div className="col-12 job_desc_question_label">
                              <table className="table table-hover align-middle">
                              <tbody>
                                <tr className="tr-header">
                                <td>{table.id}  <img title={table.help} className="close-btn" src={Information} /></td>
                                <td align="right"><button className="btn fm-btn" onClick={() => addRow(table.id)} removeRow={(rowId) => removeRow(table.id, rowId)} type="button">{t('add')}</button></td>
                                </tr>
                               </tbody>
                              </table> 
                             
                              </div>
                              <div className="col-12">
                                <ImproveJobQuestion key={table.id}  tableId={table.id} rows={table.rows} addRow={() => addRow(table.id)} removeRow={(rowId) => removeRow(table.id, rowId)} handleInputChange={(rowId, field, value) => handleInputChange(table.id, rowId, field, value)} />
                              </div>
                            </div>
                            
                          ))}
                          <div className="col-12 p-4">
                            <div className="d-grid gap-2 d-md-flex justify-content-md-start">
                              <button className="btn btn-m fm-btn me-md-2 px-5" type="button" onClick={openTestPopUp}>{t('testimprovement')}</button>
                            </div>
                          </div>
                          <div className="col-12 p-4">
                            <div className="d-grid gap-2 d-md-flex justify-content-md-start">
                              
                            </div>
                          </div>
                          
                      
                         
                          
                          <div className="col-12 fm-content-box pt-4">
                              <div className="col-12">
                                <table width="100%" className=" align-middle">
                                 <tbody>
                                    
                                    <tr>
                                      <td><h3>{t('description')}</h3></td>
                                    </tr>
                                    <tr>
                                      <td>
                                        {productDetails ? <SystemPrompt text={productDetails} /> : ''} 
                                      </td>
                                    </tr>
                                    <tr>
                                      <td><br /><hr /><br /></td>
                                    </tr>
                                    <tr>
                                      <td><h3>{t('output')}</h3></td>
                                    </tr>
                                    <tr>
                                      <td>{executeMessage ? ( <> {executeMessage} { showSaveMsgButton ? (<><br /> 
                                        <button  className="btn btn-m fm-btn me-md-2 px-5" type="button" onClick={saveOutputHistory}>{t('saveoutput')}</button>
                                        <button className="btn btn-m fm-btn" onClick={() => saveJobDescription()} type="button">{t('saveimprovement')}</button>  {savePromptMsg ? savePromptMsg : ''}
                                        </> ) : '' }</> ):''} 
                                       {outputSavedMsg ? outputSavedMsg : '' } <br /><br />
                                      

                                      
                                      </td>
                                    </tr>
                                     </tbody>
                                  </table>
                              </div>
                            </div>
                        
                        
                     </div>
                     
                  </div>
                  {showModal ? 
                    <div className="modal display-block">
                      <div className="modal-main">
                        <div style={{float: 'right', marginTop: '-15px', marginRight: '-5px'}}>
                          <img src={CloseIcon} onClick={handleClose} className="close-btn" width="12" />
                        </div>
                        <div className="modal-contentt">
                          <div className="col-12 p-4">
                            <div className="col-12 job_desc_question_label">{t('products')}</div>
                            <div className="col-12">
                              <div className="mb-3">
                                <input type="text" className="form-control" placeholder="" aria-label="" name="isbn" aria-describedby="" onChange={handleChange} />
                              </div>
                            </div>
                          </div>
                          <div className="col-12 p-4">
                            <div className="col-12">
                              <button className="btn btn-m fm-btn me-md-2 px-5" type="button" onClick={handleSubmit}>{t('testimprovement')}</button>
                            </div>
                          </div>  
                        </div>
                      </div>
                    </div>
                  : ''}
                  <div className=" pb-4">
                  </div>  
                </main>
              </div>
            </div>
        	
        </div>
        )
}
export default ImproveJob;