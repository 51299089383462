import {Link, useNavigate} from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import AssistantService from "../services/AssistantService";
import "../styles/bootstrap.min.css";
import SideBar from "./Sidebar";
import LanguageSection from "./LanguageSection";
import TopBar from "./Topbar";
import OurValues  from "../images/feed.png";

import { useTranslation } from 'react-i18next';

function Tools() {
  document.body.style.backgroundColor = '#FFFFFF';
  const [assistantList, setAssistantList] =  useState([]);
  const { t } = useTranslation();
  useEffect(() => {
    AssistantService.assistantsList().then(
      (response) => {
        setAssistantList(response.data)
      }
    ).catch(
      (error) => {
        console.log("error fetching assistants", error);
      }
    )
  }, [])
	return (
        <div  >
            <div className="container-fluid">
              <div className="row">
                
                

                <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                  <div className="d-flex justify-content-between flex-wrap flex-md-nowrap d-none d-block align-items-center pt-3 pb-2 mb-3">
                      <button className="navbar-toggler bg-light position-absolute d-md-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
                            <i className="bi bi-list"></i>
                          </button>
                    </div>
                  <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 mb-3">
                    <div className="col-md-3 col-lg-7 px-4 ">
                     <h1 className="h1">{t('tools')}</h1>
                     <div className="breadcrumb">
                        {t('tools')}
                      </div>
                    </div>
                    <TopBar />
                    
                  </div>
                  
                  <div className="container-fluid" >
                    <div className="row tools"> 
                          <Link  className="nav-link" to={"/tools/pim"}>
                          <div className="fm-top-box-tools col-xs-12 col-sm-12 col-md-6 col-lg-2 col-lg-offset-1 m-2 p-6" align="center" >
                            <img src={OurValues} width="140" />
                          </div>
                          </Link>
                         {/*
                         <div className={dashboardSection=='language' ? 'fm-top-box-active fm-top-box col-xs-12 col-sm-12 col-md-6 col-lg-2 col-lg-offset-1 m-2 p-3' : 'fm-top-box col-xs-12 col-sm-12 col-md-6 col-lg-2 col-lg-offset-1 m-2 p-3'} align="center" onClick={() => {setDashboardSection('language'); setSavePromptMsg("");}}>
                          <img src={Language} />
                            <h1>{t('language')}</h1>
                         </div>
                         
                         <div className="fm-top-box col-xs-12 col-sm-12 col-md-6 col-lg-2 col-lg-offset-1 m-2 p-3" align="center">
                          <img src={JobResult} />
                            <h1>{t('jobresult')} </h1>
                         </div>
                         
                         <div className="fm-top-box col-xs-12 col-sm-12 col-md-6 col-lg-2 col-lg-offset-1 m-2 p-3" align="center">
                          <img src={Feedback} />
                            <h1>{t('feedback')} </h1>
                         </div>
                       */}
                     </div>
                  </div>
                  
                  
                  
                  
                </main>
              </div>
            </div>
        	
        </div>
        )
}
export default Tools;